import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'

type GetProtocol = () => Promise<void>

type Return = GetProtocol

const useGetProtocol = (uuid: string): Return => {
  const submit = functional.use(Routes.VERSAMMLUNG_GET_PROTOCOL)

  const getProtocol: GetProtocol = async () => {
    const resultLink = await submit({ uuid })

    if (typeof window !== 'undefined') {
      const link = document.createElement('a')
      link.href = resultLink
      link.target = '_blank'
      link.rel = 'noopener noreferrer'
      link.click()
    }
  }

  return getProtocol
}

export default useGetProtocol
