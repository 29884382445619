import { useDispatch, useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'
import { setLocal } from '../../../../../../redux/action/local'
import useToast, { Type } from '../../../../../hooks/useToast'
import props from '../../../../../../redux/props'
import { useParams } from 'react-router-dom'

type Finalize = () => Promise<boolean>

type Return = Finalize

const useFinalize = (): Return => {
  const moderationState: ModerationState = useSelector((s) => s[moderationStateProp])
  const submit = functional.use(Routes.VERSAMMLUNG_FINALIZE)
  const dispatch = useDispatch()
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const { companyUUID, type } = useParams()

  const finalize: Finalize = async () => {
    if (!moderationState) return false
    if (!moderationState.isDemoMode) {
      const versammlungErgebnis = await submit({ versammlungErgebnis: moderationState.versammlungErgebnis })
      if (!versammlungErgebnis) return false
      dispatch(setLocal(moderationStateProp, { ...moderationState, versammlungErgebnis }))
      toast(t.versammlung.moderate.end.finalize.success, Type.SUCCESS)
      setTimeout(() => {
        window.location.href = `/admin/company/versammlung/${type}/${companyUUID}`
      }, 3000)
      return true
    } else {
      toast(t.versammlung.moderate.end.finalize.demoMode)
      return false
    }
  }

  return finalize
}

export default useFinalize
