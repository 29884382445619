import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import useToast, { Type } from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

type Start = () => Promise<void>

type Return = Start

type Callback = () => void

const useBroadcastProtocol = (versammlungUUID: string, callback: Callback): Return => {
  const broadcast = functional.use(Routes.VERSAMMLUNG_PROTOCOL_BROADCAST)
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  const start: Start = async () => {
    const status = await broadcast({ versammlungUUID })
    callback()
    if (status) {
      toast(t.versammlung.list.broadcast.success, Type.SUCCESS)
    } else {
      toast(t.versammlung.list.broadcast.error)
    }
  }

  return start
}

export default useBroadcastProtocol
