import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Versammlung from './Versammlung/Versammlung'

const Admin = () => {
  return (
    <Layout authLevelRequired={false} noFooter>
      <Routes>
        <Route path={'versammlung/*'} element={<Versammlung />} />
        <Route path={'*'} element={<Navigate replace to="/" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
