import { Route, Routes } from 'react-router-dom'
import Signature from './Signature/Signature'

const Versammlung = () => {
  return (
    <Routes>
      <Route path={'/signature/:versammlungUUID/:mail'} element={<Signature />} />
    </Routes>
  )
}

export default Versammlung
