import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { useState } from 'react'
import Modal from '../../../../../../UI/Modal/Modal'
import Button from '../../../../../../UI/Button/Button'

type Props = {
  trigger: () => void
}

const ManualFreigabe: React.FC<Props> = ({ trigger }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  return (
    <div className="border-t border-gray flex justify-end">
      <Button text={t.versammlung.upsert.freigabe.manual.cta} onClick={toggle} className="mt-2" />
      <Modal onConfirm={trigger} show={open} onClose={toggle} title={t.versammlung.upsert.freigabe.manual.title}>
        {t.versammlung.upsert.freigabe.manual.hint}
      </Modal>
    </div>
  )
}

export default ManualFreigabe
