import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Button from '../../../../UI/Button/Button'
import { useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import useBroadcastProtocol from './hooks/useBroadcastProtocol'

type Props = { versammlungUUID: string }

const Broadcast: React.FC<Props> = ({ versammlungUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [openBroadcast, setOpenBroadcast] = useState(false)
  const startBroadcast = useBroadcastProtocol(versammlungUUID, () => setOpenBroadcast(false))

  const toggle = () => setOpenBroadcast(!openBroadcast)

  return (
    <div>
      <Button onClick={toggle} text={t.versammlung.list.broadcast.open} />
      <Modal noForm onConfirm={startBroadcast} show={openBroadcast} onClose={toggle} title={t.versammlung.list.broadcast.title}>
        {t.versammlung.list.broadcast.text}
      </Modal>
    </div>
  )
}

export default Broadcast
