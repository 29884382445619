import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import useModerationState from '../../hooks/useModerationState'
import Voting from './Voting'
import Input from '../../../../../../UI/Input/Input'
import { useState } from 'react'
import { Beteiligung } from '../../../../../../../types/Beteiligung'
import useGetCurrentTOP from '../../hooks/useGetCurrentTOP'
import GlobalToggles from './Toggles/Global'

const VotingList: React.FC<{ anwesendOnly?: boolean }> = ({ anwesendOnly }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const moderationState = useModerationState()
  const currentTop = useGetCurrentTOP()
  const [search, setSearch] = useState<string>('')
  if (!moderationState || !currentTop) return <div></div>
  const { versammlungErgebnis } = moderationState

  const getName = (b: Beteiligung) => {
    if (b.personUUID) {
      return `${b.person.kontakt.vorname} ${b.person.kontakt.nachname}`
    } else if (b.companyUUID) {
      return b.company.base.name
    }
  }

  const filter = (b: Beteiligung) => {
    const name = getName(b)
    return name.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">{t.versammlung.moderate.votingList.title}</div>
      {!anwesendOnly && <GlobalToggles absoluteTeilnehmer={versammlungErgebnis.absoluteTeilnehmer} />}
      <Input value={search} onChange={setSearch} placeholder={t.versammlung.moderate.search} className="mt-1" />
      <div className="flex flex-col gap-1 mt-2">
        {versammlungErgebnis.absoluteTeilnehmer.filter(filter).map((b) => (
          <Voting key={b.personUUID || b.companyUUID} anwesendOnly={anwesendOnly} beteiligung={b} />
        ))}
      </div>
    </div>
  )
}

export default VotingList
