import { useDispatch, useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'
import { setLocal } from '../../../../../../redux/action/local'
import useToast from '../../../../../hooks/useToast'
import props from '../../../../../../redux/props'

type SaveEndDate = () => Promise<boolean>

type Return = SaveEndDate

const useSaveEndDate = (): Return => {
  const moderationState: ModerationState = useSelector((s) => s[moderationStateProp])
  const submit = functional.use(Routes.VERSAMMLUNG_SAVE_END_DATE)
  const dispatch = useDispatch()
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  const saveEndDate: SaveEndDate = async () => {
    if (!moderationState) return false
    if (!moderationState.isDemoMode) {
      const versammlungErgebnis = await submit({ uuid: moderationState.versammlungErgebnis.uuid })
      if (!versammlungErgebnis) return false
      dispatch(setLocal(moderationStateProp, { ...moderationState, versammlungErgebnis }))
      return true
    } else {
      toast(t.versammlung.moderate.end.saveDate.demoMode)
      return false
    }
  }

  return saveEndDate
}

export default useSaveEndDate
